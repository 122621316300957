import './App.css';
import Embed from './components/Embed';
import Lander from './components/Lander';
import MatchesTable from './components/MatchesTable';
import { fetchFromApi } from './utils/api';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

fetchFromApi('/teams')
  .then(response => response.json())
  .then(data => {
    console.log(data);
  });

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<Lander />} />
          <Route path="/embed/:team_key" element={<Embed />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
        
