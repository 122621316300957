import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import MatchesTable from './MatchesTable';

const Embed = () => {
  const { team_key } = useParams(); // Using useParams to access the team_key parameter
  const table = team_key ? <MatchesTable teamKey={team_key} iframe={true} /> : <>"Error: No team provided"</>; // Passing team_key as a prop to MatchesTable
  return <div style={{width: '100%', minWidth: '100vw'}}>{table}</div>;
};

export default Embed;