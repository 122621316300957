import React, { useEffect, useRef, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import TeamIcon from './TeamIcon';
import styles from './MatchesTable.module.css';
import { fetchFromApi } from '../utils/api';

interface Match {
  match_id: string;
  team_key: string;
  opponent_key: string;
  team_name: string;
  opponent_name: string;
  team_logo_url: string;
  opponent_logo_url: string;
  event_key: string;
  event_name: string;
  date: string;
  time: string;
  timestamp: number;
}

interface MatchesTableProps {
  teamKey: string;
  iframe: boolean;
}

const MatchesTable: React.FC<MatchesTableProps> = ({ teamKey, iframe }) => {
  const [matches, setMatches] = useState<Match[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const tableRef = useRef<HTMLDivElement>(null); // Create a ref for the table
  const [tableWidth, setTableWidth] = useState(0); // State to store the table width

  useEffect(() => {
    // Function to update the width
    const updateWidth = () => {
      if (tableRef.current) {
        setTableWidth(tableRef.current.offsetWidth); // Update the width state based on the current table width
      }
    };

    updateWidth(); // Update width initially in case the table renders with a definite size
    window.addEventListener('resize', updateWidth); // Add resize listener to update width on window resize

    return () => {
      window.removeEventListener('resize', updateWidth); // Cleanup the event listener when the component unmounts
    };
  }, []); // Empty dependency array to run only on mount and unmount

  useEffect(() => {
    if (!teamKey) {
      setMatches([]);
      setLoading(false);
      return;
    }

    setLoading(true);
    fetchFromApi(`/matches/${teamKey}/`)
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to fetch matches');
        }
        return response.json();
      })
      .then((data: { [key: string]: Match }) => {
        const currentDateString = new Date().toISOString().slice(0, 10); // get current date in yyyy-mm-dd format
        const futureMatches = Object.values(data).filter(match => {
          return match.date >= currentDateString; // compare match date with current date
        });
        setMatches(futureMatches);
      })
      .catch(error => {
        console.error('Error fetching matches:', error);
        setMatches([]);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [teamKey]);

  const formatTime = (timestamp: number) => {
    const date = new Date(timestamp * 1000); // Convert timestamp to Date object
    let hours: number | string = date.getHours(); // Get local hours
    let minutes: number | string = date.getMinutes(); // Get local minutes
    hours = hours < 10 ? '0' + hours : hours; // Format hours to two digits
    minutes = minutes < 10 ? '0' + minutes : minutes; // Format minutes to two digits
    return `${hours}:${minutes}`; // Return formatted time "hh:mm"
  };

  function formatDate(timestamp: number): string {
    const date = new Date(timestamp * 1000); // Convert timestamp to Date object

    const months = ["January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"];

    // Get local date components
    const year = date.getFullYear(); // Local year
    const month = date.getMonth(); // Month is 0-indexed, January is 0
    const day = date.getDate(); // Local day of the month

    const monthName = months[month];

    // Function to determine the correct ordinal suffix
    const getOrdinalSuffix = (day: number): string => {
      if (day > 3 && day < 21) return 'th';
      switch (day % 10) {
        case 1: return 'st';
        case 2: return 'nd';
        case 3: return 'rd';
        default: return 'th';
      }
    };

    // Combine components into the final string
    return `${day}${getOrdinalSuffix(day)} ${monthName}`;
  }

  return (
    <div className={styles.matchesTableContainer} style={iframe ? {height: '100vh'} : {}} ref={tableRef}>
      {iframe && <div className={styles.matchesTableHeader}>Upcoming matches</div>}
      <div className={styles.matchesTable}>
      {loading ? (
        <div className={styles.loadingContainer}><Spinner animation="border" size="sm" style={{ marginRight: '7px' }} /> Loading...</div>
      ) : matches.length > 0 ? (
        matches.map(match => (
          <div className={styles.matchRow} key={match.match_id}>
            <div className={styles.date}>
              <div className={styles.datePart}>{formatDate(match.timestamp)}</div>
              <div className={styles.timePart}>{formatTime(match.timestamp)}</div>
            </div>
            <div className={styles.team}>
              {tableWidth > 480 && <>
                <span className={styles.teamLabel}>{match.team_name}</span>
                <TeamIcon name={match.team_name} url={`https://cs2-feed-team-logos.s3.eu-west-1.amazonaws.com/${match.team_key}.jpg`} size={tableWidth / 15} iconMargin={0} />
              </>}
              {tableWidth <= 480 && <>
                <TeamIcon name={match.team_name} url={`https://cs2-feed-team-logos.s3.eu-west-1.amazonaws.com/${match.team_key}.jpg`} size={tableWidth / 15} />
                <span className={styles.teamLabel}>{match.team_name}</span>
              </>}
            </div>
            <div className={styles.vs}>VS</div>
            <div className={styles.opponent}>
              <TeamIcon name={match.opponent_name} url={`https://cs2-feed-team-logos.s3.eu-west-1.amazonaws.com/${match.opponent_key}.jpg`} size={tableWidth / 15} />
              <span className={styles.opponentLabel}>{match.opponent_name}</span>
            </div>
          </div>
        ))
      ) : (
        <div className={styles.loadingContainer} style={{color: '#444'}}>No upcoming matches found.</div>
      )}
      </div>
      {iframe && <div className={styles.matchesTableFooter}>Powered by <a href="https://cs2lens.com" target='_top'><img src={"https://cs2lens.com/promo/cs2lens-logo-v2-transparent.png"} className={styles.lensLogo}/></a></div>}
    </div>
  );
};

export default MatchesTable;
