import Icon from "./Icon";

const TeamIcon = ({name, url, size, iconMargin, opacity} : {
    name: string,
    url: string,
    size: number,
    flagMargin?: number,
    iconMargin?: number,
    opacity?: number,
  }) => {
  return <Icon 
    name={name}
    size={size}
    url={url}
    shadowStrength={0.8}
    shadowDistance={1}
    margin={iconMargin ?? 10}
    opacity={opacity ?? 1}
  />;
}

export default TeamIcon;