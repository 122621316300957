import { useEffect, useState, useRef } from "react";
import { Dropdown, FormControl, Spinner } from "react-bootstrap";
import { fetchTeams } from "../utils/team_cache";
import { TeamsData } from "../utils/types";
import TeamIcon from "./TeamIcon";

const TeamPicker = ({ isOpen, selectedTeam, wideMode, canSelectAny, label, btnClassName, btnClassNameWide, onToggle, onSelect }: {
  isOpen: boolean,
  selectedTeam: string | undefined,
  wideMode: boolean,
  btnClassName: string,
  btnClassNameWide: string,
  canSelectAny: boolean,
  label: string,
  onToggle: (isOpen: boolean) => void,
  onSelect: (team: string) => void
}) => {

  const [teams, setTeams] = useState<TeamsData>(JSON.parse(sessionStorage.getItem('teamscache') || '{}'));
  const [searchText, setSearchText] = useState("");
  const [filteredTeams, setFilteredTeams] = useState<TeamsData | undefined>(undefined);
  const [isFiltering, setIsFiltering] = useState(false);
  const searchInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (!sessionStorage.getItem('teamscache')) {
      fetchTeams().then((data) => { setTeams(data); })
    }
    if (isOpen) {
      setTimeout(() => searchInputRef.current?.focus(), 100);
    }
  }, [isOpen]);

  const handleTeamSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
    setIsFiltering(true);
    setTimeout(() => {
      if (event.target.value === "") {
        setFilteredTeams(undefined);
      } else {
        setFilteredTeams(
          Object.keys(teams)
            .filter(team => team.toLowerCase().includes(event.target.value.toLowerCase()))
            .reduce((filtered, team) => {
              filtered[team] = teams[team];
              return filtered;
            }, {} as TeamsData)
        );
      }
      setIsFiltering(false);
    }, 500);
  };

  const buttonClassName = wideMode ? btnClassNameWide : btnClassName;

  return (
    <Dropdown
      className="teams-dropdown"
      onToggle={onToggle}
      show={isOpen}
      onSelect={(eventKey: any, event: Object) => onSelect(eventKey)}
      key="teams-dropdown"
    >
      <Dropdown.Toggle variant="secondary" id="dropdown-basic" className={buttonClassName} size={"sm"}>
        {selectedTeam && selectedTeam !== 'Any' ? <>{selectedTeam in teams ?
          <TeamIcon
            url={teams[selectedTeam].team_logo_url}
            size={14}
            name={selectedTeam}
          /> : undefined}<span>{selectedTeam in teams ? teams[selectedTeam].team_name : <><Spinner animation="border" size="sm" style={{ marginRight: 15, color: "#555" }} />Loading...</>}</span></> : label}
      </Dropdown.Toggle>
      <Dropdown.Menu className='teams-dropdown-menu'>
        <FormControl
          type="text"
          placeholder="Search team"
          className="mb-2 teams-search-input"
          onChange={handleTeamSearch}
          value={searchText}
          ref={searchInputRef}
        />
        <Dropdown.Divider />
        {canSelectAny &&
          <>
            <Dropdown.Item key={`team_Any`} eventKey={"Any"}>Any</Dropdown.Item>
            <Dropdown.Divider />
          </>
        }
        {isFiltering && <div className="small mb-2 m-3" style={{ color: '#777' }}><Spinner size="sm" style={{ marginRight: '10px' }} />Loading...</div>}
        {searchText === "" && !isFiltering && <div className="small mb-2 m-3" style={{ color: '#777' }}>Type to search teams</div>}
        {(filteredTeams && !isFiltering ? Object.keys(filteredTeams) : []).map((team) => (
          <Dropdown.Item key={`team_${team}`} eventKey={team}>
            <>
              <TeamIcon
                url={teams[team].team_logo_url}
                size={14}
                name={team}
                iconMargin={15} />
              <span>{teams[team].team_name}</span>
            </>
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default TeamPicker;