export const getApiUrl = () =>
  process.env.REACT_APP_STAGE !== 'dev' 
    ? 'https://3iry6km270.execute-api.eu-west-1.amazonaws.com' 
    : 'https://3iry6km270.execute-api.eu-west-1.amazonaws.com';

export async function fetchFromApi(endpoint: string, options?: RequestInit): Promise<Response> {
  const url = `${getApiUrl()}${endpoint}`;

  const mergedOptions = {
    ...options,
    headers: {
      ...(options?.headers),
    },
  };
  return fetch(url, mergedOptions);
};

export async function fetchFromApiUrl(url: string, options?: RequestInit): Promise<Response> { 
  const mergedOptions = {
    ...options,
    headers: {
      ...(options?.headers),
    },
  };

  return fetch(url, mergedOptions);
};

export async function fetchFromS3(url: string, options?: RequestInit): Promise<Response> { 
  const mergedOptions = {
    ...options,
    headers: {
      ...(options?.headers),
    },
  };

  return fetch(url, mergedOptions);
};
