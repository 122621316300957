const Icon = ({name, size, prefix, postfix, url, margin, opacity, shadowStrength, shadowDistance} : {
  name: string,
  size: number,
  postfix?: string,
  prefix?: string,
  url?: string,
  margin?: number,
  opacity?: number,
  shadowStrength: number,
  shadowDistance: number,
}) => {
const baseUrl = window.location.origin;
const sc = `rgb(0, 0, 0, ${shadowStrength})`
const sd = shadowDistance;
const finalUrl = url || `${baseUrl}/${prefix}${name}${postfix}`
return (
  <img
    src={finalUrl}
    alt={name}
    style={{
      position: "relative",
      top: -1,
      marginRight: margin ?? 10,
      opacity: opacity ?? 1,
    }} 
    width={size}
    onError={(e) => (e.currentTarget.src = `${baseUrl}/question-mark.png`)}
  />
);
}

export default Icon;