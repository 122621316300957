import React, { useState } from 'react';
import TeamPicker from './TeamPicker';
import MatchesTable from './MatchesTable';
import styles from './Lander.module.css';

const Lander = () => {
  const getTeamFromURL = () => {
    const searchParams = new URLSearchParams(window.location.search);
    return searchParams.get('team');
  };

  const [selectedTeam, setSelectedTeam] = useState<string>(getTeamFromURL() || sessionStorage.getItem('cs2lens-feed-team-key') || '');
  const [activeDropdown, setActiveDropdown] = useState<string>('');

  const handleDropdownToggle = (dropdown: string) => {
    setActiveDropdown(old => old === dropdown ? '' : dropdown);
  };

  function generateIframeTag(teamKey: string): string {
    // Define the base URL or path for the embed
    const baseUrl: string = "https://feed.cs2lens.com/embed/";

    // Construct the full URL by appending the teamKey
    const fullUrl: string = `${baseUrl}${encodeURIComponent(teamKey)}`;

    // Construct the iframe tag
    const iframeTag: string = `<iframe src="${fullUrl}" width="600" height="400" frameborder="0" style="border-radius: 5px; overflow: hidden; border: none; box-shadow: 0 4px 8px rgba(0, 0, 0, 0.33);"></iframe>`;

    return iframeTag;
  }

  return (
    <div className={styles.centerContainer}>
      <div className={styles.inlineContainer}>
        <label htmlFor="team-picker" className={styles.inlineLabel}>Choose your team:</label>
        <TeamPicker
          isOpen={activeDropdown === 'team'}
          selectedTeam={selectedTeam}
          wideMode={true}
          canSelectAny={false}
          label="Team"
          btnClassName={styles.teamFilterButton}
          btnClassNameWide={styles.teamFilterButton}
          onToggle={(isOpen) => handleDropdownToggle('team')}
          onSelect={(team: string) => {
            setSelectedTeam(team);
            sessionStorage.setItem('cs2lens-feed-team-key', team);
          }}
        />
      </div>
      <div style={{ marginTop: '20px' }}>
        <div style={{ height: '500px', minHeight: '200px' }}>
          {selectedTeam &&
            <iframe title="matchfeed"
              src={`https://feed.cs2lens.com/embed/${selectedTeam}`}
              width="100%"
              height="100%"
              style={{
                borderRadius: "5px",
                overflow: "hidden",
                border: "none",
              }}>
        </iframe>
          }
      </div>
      {selectedTeam && <>
        <h2>Embed to your website</h2>
        <textarea
          value={generateIframeTag(selectedTeam)}
          readOnly
          className={styles.iframeTextarea}
          rows={2}
        />
      </>}
    </div>
    </div >
  );
};

export default Lander;